import React, {useEffect, useState} from 'react';
import './App.css';
import * as firebase from 'firebase/app';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

function App() {
  const [page, setPage] = useState('about');

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyDViMTkbeBZ-Fruf42KXlxzth7qbXZg8ZA",
      authDomain: "jeffrey-c-hooper.firebaseapp.com",
      databaseURL: "https://jeffrey-c-hooper.firebaseio.com",
      projectId: "jeffrey-c-hooper",
      storageBucket: "jeffrey-c-hooper.appspot.com",
      messagingSenderId: "974051447743",
      appId: "1:974051447743:web:bd0646c5cfd7779be7870a",
      measurementId: "G-7V9TF1SPDS"
    };

    firebase.initializeApp(firebaseConfig);
  }, []);

  const goToAbout = () => {
    setPage('about');
  }

  const goToProjects = () => {
    setPage('projects');
  };

  const goToThoughts = () => {
    setPage('thoughts');
  }

  return (
    <Container>
      <Container style={{ background: 'mediumpurple' }}>
        <h2 style={{ color: 'white' }}>Jeffrey Hooper</h2>
        { page !== 'about' && <Button style={{ color: 'white' }} onClick={goToAbout}>About</Button> }
        { page !== 'projects' && <Button style={{ color: 'white' }} onClick={goToProjects}>Projects</Button> }
        { page !== 'thoughts' && <Button style={{ color: 'white' }} onClick={goToThoughts}>Thoughts</Button> }
      </Container>

      <Container>
        { page === 'about' && <About /> }
        { page === 'projects' && <Projects /> }
        { page === 'thoughts' && <Thoughts /> }
      </Container>
    </Container>
  );
}

const About = () => {
  return (
    <Container><img style={{ width: 'inherit' }} src={'/resume.png'} alt={'Resume'} /></Container>
  );
}

const Projects = () => {
  return (
    <Container>
      <CardWithTopMargin>
        <a href='https://github.com/jeffreychooper'>GitHub</a>
        <p>I have various public repositories on my GitHub.</p>
        <p>Most of these should have a readme describing a little bit about them.</p>
      </CardWithTopMargin>
      <CardWithTopMargin>
        <a href='https://thedecidomatic.web.app'>The Decidomatic</a>
        <p><strong>Sort of incomplete</strong> A website to help you decide things with other people.</p>
        <p>
          Built with <a href='https://reactjs.org/'>React</a>
          , <a href='https://react-bootstrap.github.io/'>React Bootstrap</a>
          , and <a href='https://firebase.google.com/'>Firebase Realtime Database</a>
        </p>
        <p>Hosted with <a href='https://firebase.google.com/'>Firebase</a></p>
        <p>I've also created a new version of the decider that is mostly the same, but is done using a similar frontend but an Express backend.</p>
        <a href='https://protected-refuge-05293.herokuapp.com/'>New Decider Version</a>
      </CardWithTopMargin>
      <CardWithTopMargin>
        <a href='https://goofy-piglet-0001.herokuapp.com'>Disney Bucket List</a>
        <p>A website for tracking what you want to do on your next Disney World trip.</p>
        <p>
          Built using <a href='https://nodejs.org/'>Node</a>
          , <a href='https://reactjs.org/'>React</a>
          , <a href='https://react-bootstrap.github.io/'>React Bootstrap</a>
          , <a href='https://www.apollographql.com/'>Apollo</a>/<a href='https://graphql.org/'>GraphQL</a>
          , <a href='https://www.mongodb.com/'>MongoDB</a>
          , and <a href='https://firebase.google.com/'>Firebase Auth</a>
        </p>
        <p>Hosted on <a href='https://heroku.com'>Heroku</a></p>
      </CardWithTopMargin>
      <CardWithTopMargin>
        <a href='/'>This Personal Page</a>
        <p>A website for showing my personal projects and thoughts.</p>
        <p>Built with <a href='https://reactjs.org/'>React</a> and <a href='https://material-ui.com/'>Material-UI</a></p>
        <p>Hosted on <a href='https://firebase.google.com/'>Firebase</a></p>
      </CardWithTopMargin>
    </Container>
  );
};

const Thoughts = () => {
  return (
    <Container>
      <CardWithTopMargin>
        <h3>The Decidomatic</h3>
        <p>
          I just finished up The Decidomatic! I got the idea when
          using <a href='https://helpmedecideplease.com/'>another website</a> created to help people decide things. The
          biggest difference is that everyone can rate the choices on their own device. We had been using 'help me
          decide please' to decide what to eat, but my dad likes to please other people, and I wasn't sure if he was rating the
          things he wanted as high as he really felt.
        </p>
        <p>
          Therefore, <em>The Decidomatic</em>! I want to get some practice with iOS/Swift and Android/Kotlin, so I would
          like to build those versions as well. The website needs some work to look really great on mobile, too.
        </p>
        <p>
          I should probably write a little entry like this on the Disney Bucket List, too! I've started writing a bit
          about every project beyond the little blurb on my projects page, so I think that would be a good idea.
        </p>
      </CardWithTopMargin>
      <CardWithTopMargin>
        <h3>My Personal Site</h3>
        <p>
          I built this site out as a place to hold links to my projects and that kind of thing. It's just static content,
          but I used React because I've been using it lately. I might go back and rewrite it in plain HTML5 and CSS. I
          don't really think it needs to be this complicated.
        </p>
      </CardWithTopMargin>
    </Container>
  );
}

const CardWithTopMargin = (props) => {
  return (
    <Card style={{ marginTop: '10px' }}>
      {props.children}
    </Card>
  );
}

export default App;
